// primary color
$p: #201d1d;
$s: #A04747;
$t: #D8A25E;
$q: #EEDF7A;

.App {
  text-align: center;

  .Home-container {
    flex-direction: row;
    width: 95%;
    border-radius: 3px;
    font-family: 'Roboto';

    .Palette {
      display: flex;
      flex-direction: row;

      .Box1 {
        width: 33%;
        height: 33px;
        background-color: $s;
      }

      .Box2 {
        width: 33%;
        height: 33px;
        background-color: $t;
      }

      .Box3 {
        width: 33%;
        height: 33px;
        background-color: $q;
      }
    }

    .Intro-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .Title {
        //'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'
        font-family: 'Helvetica Neue';
        font-size: 40px;
        padding: 20px 10px 10px 10px;
      }

      .Socials {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 40px;
        padding: 0px;
      }

      .Section-line {
        margin-top: 20px;
        height: 1px;
        width: 90%;
        background-color: $t;
      }

      .Item-photo-container {
        display: flex;
        flex-direction: row;
        width: 90%;

        .Item {
          width: 100%;
          margin-top: 15px;
          padding-bottom: 15px;
          background-color: #151313;
          border-radius: 2px;
          box-shadow: 0px 4px 8px #000;

          .Welcome {
            color: #FFF;
            font-family: Calibri, sans-serif;
            font-size: 26px;
            text-align: left;
            margin-left: 5%;
            margin-top: 20px;
          }

          .Sub-text {
            color: #FFF;
            font-family: Calibri, sans-serif;
            font-size: 18px;
            text-align: left;
            margin-left: 5%;
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
        
      }
    }

    .Intro-projects-spacing {
      height: 20px;
      width: 100%;
    }
    .Projects-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
    }

    .Item {
      margin-top: 15px;
      background-color: #151313;
      border-radius: 2px;
      width: 90%;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
    }
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: $p;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}